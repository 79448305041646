import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7c6d9533"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "proficiencies__container" }
const _hoisted_2 = {
  key: 0,
  class: "title emphasis-red"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = ["onMouseenter"]
const _hoisted_5 = ["src", "alt"]
const _hoisted_6 = { class: "name" }
const _hoisted_7 = {
  key: 0,
  class: "note"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_touch = _resolveDirective("touch")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.title), 1))
      : _createCommentVNode("", true),
    (_ctx.techs)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.techs, (tech) => {
            return _withDirectives((_openBlock(), _createElementBlock("li", {
              key: tech.name,
              onMouseenter: ($event: any) => (_ctx.emitTechYears(tech.years)),
              onMousemove: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.updateMousePosition && _ctx.updateMousePosition(...args))),
              onMouseleave: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.emitHideYearsLabel && _ctx.emitHideYearsLabel(...args)))
            }, [
              _createElementVNode("img", {
                src: _ctx.getIconPath(tech.iconName),
                class: "tech__icon",
                draggable: "false",
                alt: tech.name + ' icon'
              }, null, 8, _hoisted_5),
              _createElementVNode("p", _hoisted_6, _toDisplayString(tech.name), 1),
              (tech.note)
                ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(tech.note), 1))
                : _createCommentVNode("", true)
            ], 40, _hoisted_4)), [
              [_directive_touch, _ctx.onTouch]
            ])
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}