
import { defineComponent } from 'vue';

type CssProps = {
  backgroundImage: string,
  backgroundPosition: string,
  backgroundSize: string,
};

export default defineComponent({
  name: 'HomeComponent',
  computed: {
    portraitPath: () => require('@/assets/pictures/zach.jpg'),
    cssProps(): CssProps {
      return {
        backgroundImage: `url(${this.portraitPath})`,
        backgroundPosition: '0 -3vh',
        backgroundSize: '90%',
      };
    },
  },
});
